import {t, Trans} from '@lingui/macro'
import React from 'react'
import {useNavigate} from 'react-router-dom'
import {useGate} from 'statsig-react'
import styled from 'styled-components'

import {IconButton, Inline, ModalSplit, theme, useModalSplitSteps} from '@pleo-io/telescope'
import {Box, Stack, Text, tokens} from '@pleo-io/telescope'
import {Close, Lightbulb} from '@pleo-io/telescope-icons'

import illustration4 from '../images/laid-back.svg'
import illustration2 from '../images/sub-wallets.svg'
import vendorCardsAnimation from '../images/vendor-cards-animation.gif'
import illustration1 from '../images/waiter.svg'
import {type AnnouncementSteps, startSpendActivationSetup} from '../tracking.helpers'

const RECURRING_SPEND_PROGRESS_KEY = '@pleo/onboarding/recurring-expenses-progress'
const RECURRING_SPEND_CARD_KEY = '@pleo/onboarding/recurring-expenses-card-dismissed'

interface RecurringSpendAnnouncementProps {
    isOpen: boolean
    setShowModal: (show: boolean) => void
}
export const RecurringSpendAnnouncement = ({
    isOpen,
    setShowModal,
}: RecurringSpendAnnouncementProps) => {
    const navigate = useNavigate()
    const steps = [
        {stepId: 'manage-spend'},
        {stepId: 'money-aside'},
        {stepId: 'vendor-cards'},
        {stepId: 'setup'},
    ]
    const recurringSpendProgress = localStorage.getItem(RECURRING_SPEND_PROGRESS_KEY)

    const {activeStep, setActiveStep, nextStep, previousStep} = useModalSplitSteps({
        steps,
        initialActiveStep: recurringSpendProgress || 'manage-spend',
    })

    const handleClose = () => {
        setShowModal(false)
        localStorage.setItem(RECURRING_SPEND_PROGRESS_KEY, activeStep)
        setActiveStep(recurringSpendProgress || 'manage-spend')
    }

    React.useEffect(() => {
        startSpendActivationSetup({step: activeStep as AnnouncementSteps})
    }, [activeStep])

    const announcementStepsContent = [
        {
            stepId: 'manage-spend',
            title: t`Activate smart features for your recurring expenses`,
            text: t`Save time on administrative tasks and cut down interruptions to business operations by using sub-wallets and vendors cards.`,
            onClickPrev: undefined,
            onClickNext: nextStep,
            nextButtonLabel: t`Continue`,
            illustration: illustration1,
            illustrationBgColor: theme.colors.shade200,
        },
        {
            stepId: 'money-aside',
            title: t`Dedicated sub-wallets keep your funds organised`,
            text: t`Create a sub-wallet for each department or service to protect essential expenses and avoid overspending.`,
            onClickPrev: previousStep,
            onClickNext: nextStep,
            nextButtonLabel: t`Continue`,
            illustration: illustration2,
            illustrationBgColor: theme.colors.shade200,
        },
        {
            stepId: 'vendor-cards',
            title: t`Vendor cards keep recurring expenses on track`,
            text: t`Eliminate ad hoc spending on personal cards and create vendor cards for subscriptions and digital ads. Plus, automated data entry makes bookkeeping easier.`,
            onClickNext: nextStep,
            nextButtonLabel: t`Continue`,
            onClickPrev: previousStep,
            illustration: vendorCardsAnimation,
            illustrationBgColor: theme.colors.shade200,
        },
        {
            stepId: 'setup',
            title: t`Take a few minutes to save more time in the future `,
            text: t`Follow just a few easy steps to activate these features and their benefits. `,
            onClickPrev: previousStep,
            onClickNext: () => {
                handleClose()
                navigate('/recurring-vendors/setup-guide')
                localStorage.setItem(RECURRING_SPEND_CARD_KEY, 'dismissed')
                startSpendActivationSetup({call_to_action: 'start_setup'})
            },
            nextButtonLabel: t`Set up now`,
            illustration: illustration4,
            illustrationBgColor: theme.colors.shade200,
        },
    ]
    const stepBgColor =
        announcementStepsContent[steps.findIndex((step) => step.stepId === activeStep)]
            .illustrationBgColor

    return (
        <ModalSplit isOpen={isOpen} onDismiss={handleClose}>
            <ModalSplit.Content>
                <ModalSplit.StepIndicator
                    steps={steps}
                    activeStep={activeStep}
                    onClickStep={setActiveStep}
                />
                <ModalSplit.Steps activeStepId={activeStep}>
                    {announcementStepsContent.map((step) => {
                        return (
                            <ModalSplit.Step stepId={step.stepId} key={step.stepId}>
                                <ModalSplit.Body>
                                    {/* TO BE enabled later */}
                                    {/* {activeStep === 'manage-spend' && (
                                        <PlanTag variant="green">
                                            <Trans>Included in your plan</Trans>
                                        </PlanTag>
                                    )} */}
                                    <ModalSplit.Title>{step.title}</ModalSplit.Title>
                                    <ModalSplit.Text>{step.text}</ModalSplit.Text>
                                </ModalSplit.Body>
                                <ModalSplit.Actions>
                                    {step.onClickPrev && (
                                        <ModalSplit.BackButton onClick={step.onClickPrev} />
                                    )}
                                    {step.onClickNext && (
                                        <ModalSplit.NextButton onClick={step.onClickNext}>
                                            {step.nextButtonLabel}
                                        </ModalSplit.NextButton>
                                    )}
                                </ModalSplit.Actions>
                            </ModalSplit.Step>
                        )
                    })}
                </ModalSplit.Steps>

                {/* TO BE enabled later */}
                {/* <ModalSplit.Footer>
                    <Trans>Want to do it later?</Trans>{' '}
                    <Link href="">
                        <Trans>Send email reminder</Trans>
                    </Link>
                </ModalSplit.Footer> */}
            </ModalSplit.Content>
            <ModalSplit.IllustrationContainer
                style={{
                    backgroundColor: stepBgColor,
                }}
            >
                <ModalSplit.Steps activeStepId={activeStep}>
                    {announcementStepsContent.map((step) => {
                        return (
                            <ModalSplit.Step
                                stepId={step.stepId}
                                key={'illustration-' + step.stepId}
                            >
                                <ModalSplit.Illustration
                                    style={{
                                        paddingLeft: step.stepId === 'vendor-cards' ? 0 : 65,
                                        paddingRight: step.stepId === 'vendor-cards' ? 0 : 65,
                                    }}
                                >
                                    <img src={step.illustration} alt="" />
                                </ModalSplit.Illustration>
                            </ModalSplit.Step>
                        )
                    })}
                </ModalSplit.Steps>
            </ModalSplit.IllustrationContainer>
        </ModalSplit>
    )
}

// const PlanTag = styled(Tag)`
//     margin-top: ${tokens.spacing8};
//     margin-bottom: ${tokens.spacing8};
// `

interface RecurringSpendCardProps {
    isTablet: boolean
}
export const RecurringSpendCard = ({isTablet}: RecurringSpendCardProps) => {
    const {value: showProgressCard, isLoading} = useGate('spend_activation_setup_flow')
    const [showModal, setShowModal] = React.useState(
        localStorage.getItem(RECURRING_SPEND_PROGRESS_KEY) === null,
    )
    const isDismissed = localStorage.getItem(RECURRING_SPEND_CARD_KEY) === 'dismissed'

    if (!showProgressCard || isLoading || isDismissed) {
        return null
    }

    return (
        <>
            <Wrapper>
                <StyledBox
                    isTablet={isTablet}
                    borderRadius={8}
                    paddingTop={12}
                    paddingX={16}
                    paddingBottom={16}
                    onClick={() => setShowModal(true)}
                >
                    <Stack space={8}>
                        <Inline
                            justifyContent="space-between"
                            alignItems="center"
                            css={{width: '100%'}}
                        >
                            <Lightbulb size={16} />
                            <IconButton
                                variant="quiet"
                                size="small"
                                tooltipProps={{content: t`Close`}}
                                Icon={Close}
                                onClick={() => {
                                    localStorage.setItem(RECURRING_SPEND_CARD_KEY, 'dismissed')
                                }}
                            />
                        </Inline>
                        <Text>
                            <Trans>Activate smart features for your recurring expenses</Trans>
                        </Text>
                    </Stack>
                </StyledBox>
            </Wrapper>
            <RecurringSpendAnnouncement isOpen={showModal} setShowModal={setShowModal} />
        </>
    )
}

const Wrapper = styled.div`
    cursor: pointer;
    padding: ${tokens.spacing12} ${tokens.spacing16} ${tokens.spacing16};
    justify-self: stretch;
`
interface StyledBoxProps {
    isTablet: boolean
}

const StyledBox = styled(Box)<StyledBoxProps>`
    background-color: ${({isTablet}) =>
        isTablet ? tokens.colorBackgroundInteractiveLoud : tokens.colorBackgroundInteractive};
`
